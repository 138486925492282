import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { getUserToken } from '../utils/utils';
import ReactECharts from 'echarts-for-react';
import AlertSnackbar from '../componentes/AlertSnackbar';
import { useSelector } from 'react-redux';

const ModuloGrafico = ({ params }) => {
  const [chartOptions, setChartOptions] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [isLoading, setIsLoading] = useState(false);
  const darkMode = useSelector((state) => state.theme.darkMode);
  const startDate = useSelector(state => state.dateFilterSlice.dataIni);
  const endDate = useSelector(state => state.dateFilterSlice.dataFim);

  useEffect(() => {
    fetchApiCall();
  }, [params.apiEndpoint, startDate, endDate]);

  const fetchApiCall = async () => {
    const endpoint = params.apiEndpoint ? params.apiEndpoint : null;
    if (!endpoint) return;

    setIsLoading(true);
    const urlWithParams = `${params.apiEndpoint}?data_ini=${encodeURIComponent(startDate)}&data_fim=${encodeURIComponent(endDate)}`;

    try {
      const response = await api.get(urlWithParams, {
        headers: { 'Authorization': getUserToken() }
      });
      if (response.status === 200 && response.data) {
        formatDataForChart(response.data.dados.chartOptions);
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(`Falha ao buscar dados, código de status: ${response.status}`);
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Erro ao obter dados: ' + error.message);
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDataForChart = (data) => {
    if (data) {
      // Configuração do texto padrão
      data.textStyle = {
        color: darkMode ? '#FFFFFF' : '#000000'
      };

      // Configuração da legenda
      data.legend = {
        ...data.legend,
        textStyle: {
          color: '#FFFFFF'
        },
      };

      // Configuração das labels dos valores em todas as séries
      if (data.series && Array.isArray(data.series)) {
        data.series = data.series.map(seriesItem => ({
          ...seriesItem,
          label: {
            ...seriesItem.label,
            show: true,
            position: 'top',
            formatter: ({ value }) => {
              // Condicional para adicionar "R$" apenas se o endpoint for específico
              if (params.apiEndpoint === 'relatorios/grafico_rentabilidade_total_por_periodo_vendedor') {
                console.log(params.apiEndpoint)
                return new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).format(value);
              } else {
                console.log(params.apiEndpoint)

                return new Intl.NumberFormat('pt-BR', {
                  
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).format(value);
              }
            },
            color: '#FFFFFF',
            fontSize: 14,
            fontWeight: 'bold',
            textBorderColor: '#000000',
            textBorderWidth: 2,
            textShadowColor: 'rgba(0, 0, 0, 0.3)',
            textShadowBlur: 2,
            textShadowOffsetX: 1,
            textShadowOffsetY: 1,
          },
          // Opcional: Ajustar itemStyle para melhor contraste
          itemStyle: {
            ...seriesItem.itemStyle,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            shadowBlur: 4,
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          }
        }));
      }

      // Configuração do fundo do gráfico para melhor contraste
      data.backgroundColor = darkMode ? '#2E2E2E' : '#FFFFFF';

      // Configuração dos eixos para melhor contraste
      if (data.xAxis) {
        data.xAxis = Array.isArray(data.xAxis) ? data.xAxis.map(axis => ({
          ...axis,
          axisLine: {
            ...axis.axisLine,
            lineStyle: {
              ...axis.axisLine?.lineStyle,
              color: '#FFFFFF'
            }
          },
          axisLabel: {
            ...axis.axisLabel,
            color: '#FFFFFF'
          }
        })) : {
          ...data.xAxis,
          axisLine: {
            ...data.xAxis.axisLine,
            lineStyle: {
              ...data.xAxis.axisLine?.lineStyle,
              color: '#FFFFFF'
            }
          },
          axisLabel: {
            ...data.xAxis.axisLabel,
            color: '#FFFFFF'
          }
        };
      }

      if (data.yAxis) {
        data.yAxis = Array.isArray(data.yAxis) ? data.yAxis.map(axis => ({
          ...axis,
          axisLine: {
            ...axis.axisLine,
            lineStyle: {
              ...axis.axisLine?.lineStyle,
              color: '#FFFFFF'
            }
          },
          axisLabel: {
            ...axis.axisLabel,
            color: '#FFFFFF'
          }
        })) : {
          ...data.yAxis,
          axisLine: {
            ...data.yAxis.axisLine,
            lineStyle: {
              ...data.yAxis.axisLine?.lineStyle,
              color: '#FFFFFF'
            }
          },
          axisLabel: {
            ...data.yAxis.axisLabel,
            color: '#FFFFFF'
          }
        };
      }

      // Configuração dos ticks para melhor contraste
      data.axisPointer = {
        ...data.axisPointer,
        label: {
          ...data.axisPointer?.label,
          color: '#FFFFFF'
        }
      };

      setChartOptions(data);
    } else {
      setSnackbarSeverity('error');
      setSnackbarMessage('Dados de gráfico com formato inadequado');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div style={{ flex: 1 }}>
      {isLoading ? <p>Carregando...</p> : (
        <ReactECharts
          style={{ height: '100%', width: '100%' }}
          option={chartOptions}
          theme={darkMode ? 'dark' : 'light'}
          opts={{ renderer: 'svg' }}
        />
      )}
      <AlertSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={["error", "warning", "info", "success"].includes(snackbarSeverity) ? snackbarSeverity : "error"}
        message={snackbarMessage}
      />
    </div>
  );
};

export default ModuloGrafico;
